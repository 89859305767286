.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #45454500;
  color: white;
  padding: 5px 20px;
 
}

.logo {
  font-size: 1rem;
}

.menu-button {
  display: none;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
}

.menu {
  display: flex;
  direction: rtl;
}

.menu a {
  color: white;
  text-decoration: none;
  padding: 10px;
}

.menu a {
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .menu {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    display: none;
    background: #454545;
    transition: all 0.3s;
  }

  .menu.show {
    display: flex;
    transition: all 0.3s;
  }

  .menu a {
    text-align: center;
    border-bottom: 1px solid white;
  }

  .menu-button {
    display: block;
  }
}
