table{
    background-color: beige;
}
.buttons >button{
    margin-left: 10px;
    margin-top: 20px;
  }

  .clear-icon {
    position: relative;
    cursor: pointer;
    margin-right: 5px;
    font-size: 1rem;
    color: red; /* يمكنك تغيير اللون حسب التصميم */
}


@media screen and (min-width: 700px) {
    .col-4{
width: 100%;
    }
}

