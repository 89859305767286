@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');


@font-face {
  font-family: 'Tajawal';
  src: url('./fonts/Tajawal-Regular.ttf') format('ttf');
}

body {
  background: linear-gradient(
      to right,
      rgba(4, 4, 3, 0.57),
      rgba(16, 11, 11, 0.795)
    ),
    url(./img/bg.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

* {
  font-family: "Tajawal", system-ui;
}

.logo {
  width: 200px;
}

.grad{
  position: absolute;
  width: 100px;
  display: flex;
  justify-content: space-between;
  left: 50%;
 transform: translate(-50%, -50%);
}

::-webkit-scrollbar{
  display: none;
}
button {
  margin-left: 5px;
  
}
@media (max-width: 900px) {
  .msg {
    display: none;
  }
}


